.sendButton {
	--box-shadow: none;
}
.message-header {
	background-color: white;
	--border: none;
}

.message-footer {
	--background-color: white;
	--border: none;
	--box-shadow: none;
}
