.load-container-one {
	width: 100%;
	display: flex;
	height: 100%;
}

.lds-heart {
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	transform: rotate(45deg);
	transform-origin: 40px 40px;
}
.lds-heart div {
	top: 32px;
	left: 32px;
	position: absolute;
	width: 32px;
	height: 32px;
	background: #fff;
	animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
	content: ' ';
	position: absolute;
	display: block;
	width: 32px;
	height: 32px;
	background: #fff;
}
.lds-heart div:before {
	left: -24px;
	border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
	top: -24px;
	border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}
	5% {
		transform: scale(1.1);
	}
	39% {
		transform: scale(0.85);
	}
	45% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(0.9);
	}
}


  
  .progress {
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .progress span {
	background: #002479;
	opacity: 70%;
	height: 6px;
	width: 6px;
	display: inline-block;
	border-radius: 50%;
	animation: wave 4s ease infinite;
	margin-right: 10px;
  }

  .progress span:nth-child(1) { animation-delay: 0ms; }
  .progress span:nth-child(2) { animation-delay: 200ms; }
  .progress span:nth-child(3) { animation-delay: 400ms; }
  .progress span:nth-child(4) { animation-delay: 600ms; }
  .progress span:nth-child(5) { animation-delay: 800ms; }
  .progress span:nth-child(6) { animation-delay: 1000ms; }
  .progress span:nth-child(7) { animation-delay: 1200ms; }
  .progress span:nth-child(8) { animation-delay: 1400ms; }
  .progress span:nth-child(9) { animation-delay: 1600ms; }
  .progress span:nth-child(10) { animation-delay: 1800ms; }
  .progress span:nth-child(11) { animation-delay: 2000ms; }
  .progress span:nth-child(12) { animation-delay: 2200ms; }
  .progress span:nth-child(13) { animation-delay: 2400ms; }
  .progress span:nth-child(14) { animation-delay: 2600ms; }
  .progress span:nth-child(15) { animation-delay: 2800ms; }

  @keyframes wave {
	0%, 100% { 
	  transform: translateY(0) translateX(0);
	  background-color: #002479;    
	}

	40% { 
	  transform: translateY(-10px) translateX(0px); 
	  background-color: #00688B;    
	}
	50% { 
	  transform: translateY(-110px) translateX(20px); 
	  background-color: #6dccff;    
	}
	70% { 

		background-color: #00567b;    
	  }
	/* 60% { 
	  transform: translateY(-0px) translateX(20px); 
	  background-color: #00688B;    
	} */
	
  }
