.logo {
	width: 50%;
	margin: auto;
	margin-top: 15%;
	padding-bottom: 15%;
}

.welcome-text-container {
	padding-bottom: 5%;
}

.modal-wrapper {
	color: gray;
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.code-input {
	margin-left: auto;
	margin-right: auto;
	margin-top: 10%;
	margin-bottom: 10%;
}
.code-container {
	display: flex;
	width: 100%;
}

.phone-input {
	background-color: rgba(0, 0, 128, 0);
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1;

	border-color: rgba(0, 63, 145, 0.295);
	outline: none;
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}
