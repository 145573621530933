.card-container {
	height: 50%;
	width: 80%;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40%;
	justify-content: space-around;
	background-color: rgb(226, 226, 226);
	border-radius: 10%;
}

.bio-container {
	text-align: center;
}
.active-image {
	border-radius: 10%;
}

/* NICK */

.interactions-container {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.image {
	width: 100%;
	height: 300px;
	object-fit: cover;
	border-radius: 3%;
}

.ii {
	border: 2px solid var(--ion-color-primary);
	color: red;
	border-radius: 100%;
	background-color: rgba(255, 255, 255, 0.607);
	padding: 10px;
	font-size: 65px;
	box-shadow: 2px;
}

.ii-hand {
	color: white;
	background-color: rgb(26, 195, 34);
	border-radius: 100%;
	padding: 10px;
	font-size: 65px;
}
