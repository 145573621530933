.um-container {
	margin-top: 10px;
	display: flex;
	align-items: flex-start;
	overflow-x: scroll;
}

.um-prof-container {
	flex-shrink: 0;
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.um-prof-photo {
	width: 64px;
	height: 64px;
	object-fit: cover;
	border-radius: 100%;
	border: 1px solid var(--ion-color-primary);
}

.um-prof-name-text {
	font-weight: 300;
	margin: 5px 0 15px;
}

.conversation-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.messaging-back-arrow {
	font-size: x-large;
	margin-left: 10px;
}

.messaging-ellipsis {
	font-size: x-large;
	margin-right: 10px;
}

.messaging-header-user {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 0 !important;
}

.messaging-header-user-image {
	height: 22px;
	width: 22px;
	border-radius: 100%;
	background-color: red;
	object-fit: cover;
}

.messaging-header-user-name {
	font-size: x-small;
	margin-top: 5px;
}

.messaging-container {
	display: flex;

	width: 100%;
	min-height: 100%;
	justify-content: space-between;
	flex-direction: column;
	overflow-y: scroll;
}

.messages {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 5px 15px 40px;
	justify-content: center;
	overflow-y: scroll;
}

.message-body {
	position: relative;
	padding: 7.5px;
	display: flex;
	align-items: center;
	justify-content: center;
	word-wrap: break-word;
	/* white-space: pre-wrap; */
}

.message-body:not(:first-child) {
	margin-top: 10px;
}

.mb-received {
	margin-right: auto;
	background-color: lightgray;
	max-width: 80%;
	width: fit-content;
	text-align: left;
	word-break: break-word;
	color: rgb(24, 24, 24);
	border-radius: 10px 10px 10px 2px;
}

.mb-sent {
	text-align: right;
	max-width: 80%;
	margin-left: auto;
	width: fit-content;
	color: rgb(234, 234, 234);
	word-break: break-word;
	background-color: var(--ion-color-primary);
	border-radius: 10px 10px 2px 10px;
}

.messaging-input-container {
	position: fixed;
	display: flex;
	justify-content: center;
	width: 100%;
	bottom: 0;
	padding: 5px 10px 5px 5px;
}

.messaging-input {
	border: 1px solid gray;
	width: 90%;
	flex-grow: 1;
	border-radius: 10px;
	padding: 5px;
}

.messaging-input:focus {
	outline: none;
	border-color: var(--ion-color-primary);
}

.messaging-input-icon {
	position: absolute;
	color: var(--ion-color-primary);
	top: 7.5px;
	bottom: 0;
	right: 12px;
	font-size: x-large;
}

.messaging-chat-tail {
}

.mct-rec {
	background: radial-gradient(
		180% 180% at left -65% top -65%,
		transparent 99%,
		lightgray
	);
	left: -14px;
}

.mct-sent {
	background: radial-gradient(
		180% 180% at right -65% top -65%,
		transparent 99%,
		var(--ion-color-primary)
	);
	right: -14px;
}
