@tailwind base;
@tailwind components;
@tailwind utilities;
.title {
	font-size: larger;
	font-weight: 500;
	color: var(--ion-color-primary);
}

.subtitle {
	font-size: medium;
	font-weight: 300;
}

.close-modal-text {
	font-size: medium;
}
