@tailwind base;
@tailwind components;
@tailwind utilities;
.content-col-center-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: red;
}
