.profile-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.image-container {
	width: 100%;
	position: relative;
}

.profile-image-profile {
	height: 400px;
	width: 100%;
	object-fit: cover;
	border-radius: 0 0 10% 10%;
}

.back-arrow {
	position: absolute;
	color: white;
	font-size: xx-large;
	background-color: rgba(70, 69, 69, 0.555);
	top: 15px;
	left: 15px;
	border-radius: 20%;
	padding: 2px;
}

.text-container {
	color: black;
	padding: 10px 15px;
}

.name {
	font-size: larger;
	font-weight: 700;
}

.bio {
	font-size: smaller;
	font-weight: 300;
}
