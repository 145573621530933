.indiv-conversation-container {
	display: flex;
	/* align-items: center; */
	padding: 10px 0 10px 7.5px;
	border-top: 1px solid grey;
	border-bottom: 1px solid grey;
}

.profile-image {
	height: 48px;
	width: 48px;
	object-fit: cover;
	border-radius: 100%;
	margin-right: 10px;
}

.body-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.name-time-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
}

.conversation-name {
	font-size: larger;
	font-weight: 400;
}

.conversation-last-message {
	font-size: smaller;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.conversation-title {
	padding: 10px 0 15px 0;
}
