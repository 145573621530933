@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.bg-white-dotted {
		@apply bg-white bg-dotted-spacing-[3px] bg-dotted-gray-50;
	}
	.bg-white-ttt {
		@apply bg-white pattern-tic-tac-toe-gray-200/50 pattern-tic-tac-toe-scale-[0.5];
	}
}

@layer utilities {
	.pb-safe {
		padding-bottom: env(safe-area-inset-bottom);
	}
	.pt-safe {
		padding-top: env(safe-area-inset-top);
	}
	.mb-safe {
		margin-bottom: env(safe-area-inset-bottom);
	}
	.mt-safe {
		margin-top: env(safe-area-inset-top);
	}
	.h-safe {
		height: calc(
			100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
		);
	}
	.h-b-safe {
		height: calc(100% - env(safe-area-inset-bottom));
	}
	.h-t-safe {
		height: calc(100% - env(safe-area-inset-top));
	}

	.b-safe {
		bottom: env(safe-area-inset-bottom);
	}

	.t-safe {
		top: env(safe-area-inset-top);
	}
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
