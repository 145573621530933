
.account-photo-grid-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: scroll;
}

.change-bio-textarea {
	margin-top: 10px;
	border: 2px solid grey;
	border-radius: 10px;
}

.change-bio-textarea:focus {
	outline: none;
	border: 2px solid var(--ion-color-primary);
}

.ac-page-name-header {
	width: 100%;
	text-align: center;
	font-size: x-large;
	font-weight: 500;
	margin-bottom: 10px;
}

.ac-photos-header {
	margin-bottom: 10px;
}

.app-rotate-loading-spinner {
	animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}
