/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import "../input.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
/** Ionic CSS Variables **/
:root {
	
	/** primary **/
	--ion-color-primary: #00405c;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #f1ecdf;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;
	--ion-color-clear: #4c8eff00;
	--ion-color-pagebg: #f1ecdf;

	--ion-color-signup: #f1f5f9;
	--ion-color-signup-rgb: 61, 194, 255;
	--ion-color-signup-contrast: #ffffff00;
	--ion-color-signup-contrast-rgb: 255, 255, 255;
	--ion-color-signup-shade: #36abe0;
	--ion-color-signup-tint: #50c8ff;

	/** secondary **/
	--ion-color-secondary: #f1ecdf;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #fafafa;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;
	--ion-color-wave-blue: #006eb3;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #f1ecdf;
	--ion-color-dark-rgb: 244, 245, 248;
	--ion-color-dark-contrast: #000000;
	--ion-color-dark-contrast-rgb: 0, 0, 0;
	--ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

.ion-color-signup {
	--ion-color-base: var(--ion-color-signup);
	--ion-color-base-rgb: var(--ion-color-signup-rgb);
	--ion-color-contrast: var(--ion-color-signup-contrast);
	--ion-color-contrast-rgb: var(--ion-color-signup-contrast-rgb);
	--ion-color-shade: var(--ion-color-signup-shade);
	--ion-color-tint: var(--ion-color-signup-tint);
}
